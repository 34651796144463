<template>
  <div
    class="flex"
    :class="containerClass"
  >
    <template
      v-for="(item, itemIndex) in items"
      :key="'bread-crumb-item' + item.id || 'bread-crumb-item' + itemIndex"
    >
      <component
        :is="tag"
        v-bind="{ ...attrs, ...(item.attrs || {})}"
        :class="[item.class]"
        @click="emitSelectedEvt(item)"
      >
        {{ item[textProperty] }}
      </component>
      <span :class="spacing">
        {{ (itemIndex !== items.length - 1) ? separator : '' }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
    name: 'BaseBreadCrumb',

    props: {
        items: {
            type: Array,
            default: () => [],
            description: 'Bread crumb items'
        },
        tag: {
            type: String,
            default: 'span',
            description: 'tag for bread crumb item(default is span)'
        },
        separator: {
            type: String,
            default: '/',
            description: 'separator between text(default is /)'
        },
        spacing: {
            type: [String, Object, Array],
            default: '',
            description: 'spacing between separator and text'
        },
        textProperty: {
            type: String,
            default: 'text',
            description: 'property used for showing breadcrumb text'
        },
        containerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to root element'
        }
    },

    emits: [
        'itemSelected'
    ],

    setup (props, { emit, attrs }) {
        const emitSelectedEvt = (eventPayload) => {
            emit('itemSelected', eventPayload);
        };
        return {
            emitSelectedEvt,
            attrs
        };
    }
};
</script>
